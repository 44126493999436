import React, { useState } from 'react';
import styles from './ErrorScreen.less';
import * as Sentry from '@sentry/browser';
import Button from '../Button/Button';

const ErrorScreen = ({ message, stack = '', overlay = false } = {}) => {
  const [stackOpen, setStackOpen] = useState(false);

  return (
    <div
      className={[styles.errorScreen, overlay ? styles.overlay : ''].join(' ')}
    >
      <div>
        <h1>Something's gone wrong!</h1>
        <p>
          An error has occurred. We've logged the issue and will be looking into
          it, in the mean time please refresh your browser.
        </p>

        <div className={styles.footer}>
          <div>
            <Button onClick={() => setStackOpen(!stackOpen)} look="outline">
              {stackOpen ? 'Hide' : 'Show'} Error
            </Button>
            <Button onClick={() => Sentry.showReportDialog()} look="secondary">
              Report Feedback
            </Button>
          </div>
          <Button
            size="large"
            onClick={() => {
              window.history.back();
              setTimeout(window.location.reload, 150);
            }}
          >
            Go Back
          </Button>
        </div>

        {stackOpen && (
          <div className={styles.stack}>
            <pre>
              <code>
                <strong>{message}</strong>
                {`\r\n`}
                {stack}
              </code>
            </pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default ErrorScreen;
