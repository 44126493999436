import React from 'react';
import Image from './Image';
import defaultAvatar from '../_imgs/avatar.png';
import cls from '../_helpers/cls';
import styles from './Avatar.less';

export default ({
  person = {},
  className = '',
  small = false,
  round = false,
  ...props
}) => {
  const { fullName, firstName, assetByAvatar, avatar } = person ?? {};
  let url = defaultAvatar;

  if (assetByAvatar) {
    if (assetByAvatar.url) url = assetByAvatar.url;
    else if (assetByAvatar.urls['500x500']) url = assetByAvatar.urls['500x500'];
  } else if (avatar) {
    if (avatar.url) url = avatar.url;
    else if (avatar.urls['500x500']) url = avatar.urls['500x500'];
  }

  return (
    <Image
      {...props}
      key={url}
      src={url}
      alt={fullName || firstName || 'Unknown athlete'}
      style={{ objectFit: 'cover' }}
      placeholderWidth={500}
      placeholderHeight={500}
      className={cls(className, {
        [styles.small]: small,
        [styles.round]: round,
      })}
    />
  );
};
