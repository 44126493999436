import React from 'react';
import isSuper from '../_helpers/isSuper';

export default isSuper() ? (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 1024 1024"
    style={{ borderRadius: 10 }}
  >
    <g fill="none" fillRule="evenodd">
      <rect width="1024" height="1024" fill="#1C1E1F" />
      <polygon
        fill="#FEDA0C"
        points="875.129 717.311 881.506 713.761 751.225 481.921 645.691 648.002 406.882 212 143 677.068 239.349 677.068 405.578 384.101 640.307 812.662 746.803 645.1 787.391 717.311"
      />
    </g>
  </svg>
) : (
  <svg
    width="80px"
    height="80px"
    viewBox="0 0 80 80"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-110.000000, -42.000000)">
        <g transform="translate(110.000000, 42.000000)">
          <path
            d="M19.9592841,0 C8.980584,0 0,8.9817994 0,19.9604995 L0,60.0395005 C0,71.0182006 8.980584,80 19.9592841,80 L60.0395005,80 C71.0169852,80 80,71.0182006 80,60.0395005 L80,19.9604995 C80,8.9817994 71.0169852,0 60.0395005,0 L19.9592841,0 Z"
            fill="#FEDA0C"
          />
          <polygon
            fill="#1C1E1F"
            points="68.3694508 56.0399328 68.8676326 55.7625597 58.6894508 37.650094 50.4446023 50.6251269 31.7876326 16.5625 11.171875 52.8959687 18.6991477 52.8959687 31.6858144 30.0078612 50.0239962 63.4892104 58.3439962 50.3984045 61.5149053 56.0399328"
          />
        </g>
      </g>
    </g>
  </svg>
);
