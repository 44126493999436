import React, { PureComponent, Fragment } from 'react';
import styles from './Notifications.less';
import posed, { PoseGroup } from 'react-pose';
import SuccessIcon from '../../_icons/SuccessIcon';
import { Link } from 'react-router-dom';
import ErrorIcon from '../../_icons/ErrorIcon';
import WarningIcon from '../../_icons/WarningIcon';

export default class Notifications extends PureComponent {
  // Properties
  // =========================================================================

  static i;

  state = {
    notifications: {},
  };

  static Item = posed.li({
    enter: { opacity: 1, x: '0' },
    exit: { opacity: 0, x: '100%' },
  });

  // Constructor
  // =========================================================================

  constructor(props) {
    super(props);

    Notifications.i = this;
  }

  // Actions
  // =========================================================================

  // Actions: Static
  // -------------------------------------------------------------------------

  static success(heading, message = null, to = null, duration) {
    Notifications.i.add('success', heading, message, to, duration);
  }

  static error(heading, message = null, to = null, duration) {
    Notifications.i.add('error', heading, message, to, duration);
  }

  static warning(heading, message = null, to = null, duration) {
    Notifications.i.add('warning', heading, message, to, duration);
  }

  // Actions: Internal
  // -------------------------------------------------------------------------

  add(type, heading, message = null, to = null, duration = 5000) {
    const notifications = { ...this.state.notifications };

    const id = new Date().getTime();

    notifications[id] = { type, heading, message, to };

    this.setState({ notifications }, () => {
      setTimeout(this.remove.bind(this, id), duration);
    });
  }

  remove(id) {
    const notifications = { ...this.state.notifications };

    if (!notifications.hasOwnProperty(id)) return;

    delete notifications[id];

    this.setState({ notifications });
  }

  // Render
  // =========================================================================

  render() {
    const { notifications } = this.state;

    return (
      <ul className={styles.list}>
        <PoseGroup>
          {Object.entries(notifications).map(this._renderNotification)}
        </PoseGroup>
      </ul>
    );
  }

  _renderNotification = ([id, notification]) => {
    const children = (
      <Fragment>
        {this._getIcon(notification.type)}

        <div>
          <strong>{notification.heading}</strong>
          {notification.message}
        </div>
      </Fragment>
    );

    return (
      <Notifications.Item key={id} className={styles.li}>
        {notification.to !== null ? (
          <Link to={notification.to} className={styles.notification}>
            {children}
          </Link>
        ) : (
          <div className={styles.notification}>{children}</div>
        )}
      </Notifications.Item>
    );
  };

  // Helpers
  // =========================================================================

  _getIcon(type) {
    switch (type) {
      case 'warning':
        return WarningIcon;
      case 'error':
        return ErrorIcon;
      case 'success':
      default:
        return SuccessIcon;
    }
  }
}
