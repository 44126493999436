import dateToUTC from './dateToUTC';

export default function formatDateVariable(value) {
  if (!value) return value;

  if (Object.prototype.toString.call(value) === '[object Date]')
    return dateToUTC(value);

  if (Array.isArray(value)) return value.map(formatDateVariable);

  if (typeof value === 'object') {
    Object.keys(value).forEach((key) => {
      value[key] = formatDateVariable(value[key]);
    });
  }

  return value;
}
