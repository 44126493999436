/**
 * Converts the given date, date string, or timestamp to local time
 *
 * @param {String,Date,BigInteger} date - The date to convert from UTC (must be UTC)
 * @return {Date}
 */
export default function dateFromUTC(date) {
  date = new Date(date);

  if (isNaN(date.getTime())) date = new Date('1970-01-01T' + arguments[0]);

  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  );
}
