const mondayRegions = [
  '001',
  'AD',
  'AI',
  'AL',
  'AM',
  'AN',
  'AT',
  'AX',
  'AZ',
  'BA',
  'BE',
  'BG',
  'BM',
  'BN',
  'BY',
  'CH',
  'CL',
  'CM',
  'CR',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EC',
  'EE',
  'ES',
  'FI',
  'FJ',
  'FO',
  'FR',
  'GB',
  'GE',
  'GF',
  'GP',
  'GR',
  'HR',
  'HU',
  'IS',
  'IT',
  'KG',
  'KZ',
  'LB',
  'LI',
  'LK',
  'LT',
  'LU',
  'LV',
  'MC',
  'MD',
  'ME',
  'MK',
  'MN',
  'MQ',
  'MY',
  'NL',
  'NO',
  'PL',
  'PT',
  'RE',
  'RO',
  'RS',
  'RU',
  'SE',
  'SI',
  'SK',
  'SM',
  'TJ',
  'TM',
  'TR',
  'UA',
  'UY',
  'UZ',
  'VA',
  'VN',
  'XK',
];

const fridayRegions = ['BD', 'MV'];

const saturdayRegions = [
  'AE',
  'AF',
  'BH',
  'DJ',
  'DZ',
  'EG',
  'IQ',
  'IR',
  'JO',
  'KW',
  'LY',
  'MA',
  'OM',
  'QA',
  'SD',
  'SY',
];

const sundayRegions = [
  'AG',
  'AR',
  'AS',
  'AU',
  'BR',
  'BS',
  'BT',
  'BW',
  'BZ',
  'CA',
  'CN',
  'CO',
  'DM',
  'DO',
  'ET',
  'GT',
  'GU',
  'HK',
  'HN',
  'ID',
  'IE',
  'IL',
  'IN',
  'JM',
  'JP',
  'KE',
  'KH',
  'KR',
  'LA',
  'MH',
  'MM',
  'MO',
  'MT',
  'MX',
  'MZ',
  'NI',
  'NP',
  'NZ',
  'PA',
  'PE',
  'PH',
  'PK',
  'PR',
  'PY',
  'SA',
  'SG',
  'SV',
  'TH',
  'TN',
  'TT',
  'TW',
  'UM',
  'US',
  'VE',
  'VI',
  'WS',
  'YE',
  'ZA',
  'ZW',
];

const regionsByFirstDayOfWeek = [
  {
    name: 'monday',
    id: 1,
    regions: mondayRegions,
  },
  {
    name: 'friday',
    id: 5,
    regions: fridayRegions,
  },
  {
    name: 'saturday',
    id: 6,
    regions: saturdayRegions,
  },
  {
    name: 'sunday',
    id: 0,
    regions: sundayRegions,
  },
];

export default regionsByFirstDayOfWeek;
