import React from 'react';
import MaleFront from './_imgs/diagrams/male_front.jpg';
import MaleBack from './_imgs/diagrams/male_back.jpg';
import MaleLeft from './_imgs/diagrams/male_left.jpg';
import MaleRight from './_imgs/diagrams/male_right.jpg';
import FemaleFront from './_imgs/diagrams/female_front.jpg';
import FemaleBack from './_imgs/diagrams/female_back.jpg';
import FemaleLeft from './_imgs/diagrams/female_left.jpg';
import FemaleRight from './_imgs/diagrams/female_right.jpg';
import MaleFrontGrey from './_imgs/diagrams/male_front_grey.png';
import MaleBackGrey from './_imgs/diagrams/male_back_grey.png';
import MaleLeftGrey from './_imgs/diagrams/male_left_grey.png';
import MaleRightGrey from './_imgs/diagrams/male_right_grey.png';
import FemaleFrontGrey from './_imgs/diagrams/female_front_grey.png';
import FemaleBackGrey from './_imgs/diagrams/female_back_grey.png';
import FemaleLeftGrey from './_imgs/diagrams/female_left_grey.png';
import FemaleRightGrey from './_imgs/diagrams/female_right_grey.png';
import AppointmentIcon2 from './_icons/AppointmentIcon2';
import GameIcon2 from './_icons/GameIcon2';
import MealIcon2 from './_icons/MealIcon2';
import MeetingIcon from './_icons/MeetingIcon';
import TrainingIcon2 from './_icons/TrainingIcon2';
import TravelIcon from './_icons/TravelIcon';
import RehabIcon from './_icons/RehabIcon';
import getFirstDayOfWeek from './_helpers/getFirstDayOfWeek';

// Misc
// =========================================================================

export const INVISIBLE_SPACE = '\u200D';

export const GROUPS_LIMIT = 20;

export const FIRST_DAY_OF_WEEK = getFirstDayOfWeek();

// URLs
// =========================================================================

// WARNING: Update TLD in `APP_CONSTS.js` if changing
export const TLD = process.env.REACT_APP_TLD
  ? '.' + process.env.REACT_APP_TLD
  : process.env.NODE_ENV === 'development'
  ? '.tma.tf'
  : '.trainmyathlete.com';
// export const TLD = '.trainmyathlete.com';
// export const API_URL = 'https://api' + TLD + '/graphql';
//export const API_URL = 'https://api.trainmyathlete.asia/graphql';
export const API_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://dev.tma/graphql'
    : 'https://api' + TLD + '/graphql';
// export const API_URL =
//   process.env.NODE_ENV === 'development'
//     ? process.env.REACT_APP_API_URL ?? 'https://api.tma2.tf/graphql'
//     : 'https://api' + TLD + '/graphql';
export const UPLOAD_URL = API_URL.replace('/graphql', '/assets');

// Local Storage Keys
// =========================================================================

export const LS = {
  TOKEN: 'token',
  LOAD: 'load',
  WELLNESS: 'wellness',
  SCHEDULE_PERSON: btoa('schedulePerson'),
  SCHEDULE_TYPES: btoa('scheduleTypes'),
  CALENDAR_PREV_DATE: btoa('calPrevDate'),
  REPORTS: 'reports',
  FROM_ATHLETE_ID: btoa('fromAthleteId'),
  FILES_LAYOUT: btoa('filesLayout'),
};

// Durations
// =========================================================================

export const CHAT_POLL_INTERVAL = 10000; // 3000;

// Colours
// =========================================================================

export const COLOURS = {
  primary: '#F8C81C',
  secondary: '#8A96A0',

  success: '#48C118',
  info: '#2196F3',
  warning: '#F8C81C',
  warning2: '#F8831C',
  danger: '#EE1737',

  high: '#EE1737',
  medium: '#F86A1C',
  low: '#F5A623',

  chart1: '#2196F3',
  chart2: '#F74D71',
  chart3: '#F48AB9',
  chart4: '#48C118',
  chart5: '#7E58C3',
  chart6: '#F8C81C',
  chart7: '#30E6EE',
  chart8: '#FDEFED',
  chart9: '#EE1737',
  chart10: '#26D87C',
  chart11: '#8A96A0',
  chart12: '#0A6FC2',
  chart13: '#51ABF6',

  appointment: '#F48AB9',
  meeting: '#F48AB9',
  MEETING: '#F48AB9',
  game: '#48C118',
  competition: '#48C118',
  COMPETITION: '#48C118',
  meal: '#FF7F2F',
  MEAL: '#FF7F2F',
  MEETING2: '#AB8AF4',
  training: '#2196F3',
  TRAINING: '#2196F3',
  TRAVEL: '#8A96A0',
  workout: '#F8C81C',
  WORKOUT: '#F8C81C',

  borderLight: '#41494F',
  borderDark: '#1a1d1f',
};

export const CHART_COLOURS = [
  COLOURS.chart1,
  COLOURS.chart2,
  COLOURS.chart3,
  COLOURS.chart4,
  COLOURS.chart5,
  COLOURS.chart6,
  COLOURS.chart7,
  COLOURS.chart8,
  COLOURS.chart9,
  COLOURS.chart10,
  COLOURS.chart11,
  COLOURS.chart12,
  COLOURS.chart13,
];

export const LEFT_LABEL = {
  angle: -90,
  position: 'insideLeft',
  offset: 20,
};

// Dates
// =========================================================================

// Dates: Month Lengths
// -------------------------------------------------------------------------

const _MONTH_LENGTHS = [
  31, // Jan
  28, // Feb
  31, // Mar
  30, // Apr
  31, // May
  30, // Jun
  31, // Jul
  31, // Aug
  30, // Sep
  31, // Oct
  30, // Nov
  31, // Dec
];

/**
 * Gets the given month length, accounting for leap years
 *
 * @param {number} monthInt - The int of the month (from Date())
 * @param {number=} year - The year to check against
 * @return {number}
 */
_MONTH_LENGTHS.getMonthLength = function (
  monthInt,
  year = new Date().getFullYear()
) {
  let l = _MONTH_LENGTHS[monthInt - 1];

  // If leap year & is Feb increase month len by 1
  if (
    ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) &&
    monthInt === 1
  )
    l++;

  return l;
};

export { _MONTH_LENGTHS as MONTH_LENGTHS };

// Values
// =========================================================================

// Acronyms
// -------------------------------------------------------------------------

export const STATS = {
  'acute chronic ratio': 'A:C',
  'acute chronic load': 'A:CL',
  'modified rpe': 'RPE',
  'sleep hours': 'Avg. Sleep',
  'training minutes': 'Train Mins',
  'competition mins': 'Comp Mins',
};

// Amounts
// -------------------------------------------------------------------------

export const UNITS = [
  { value: 'KG', label: 'kg' },
  { value: 'LB', label: 'lb' },
  { value: 'KCAL', label: 'kcal' },
  { value: 'BODYWEIGHT', label: 'Body Weight' },
  { value: 'SECONDS', label: 'Sec' },
  { value: 'MINUTES', label: 'Mins' },
  { value: 'HOURS', label: 'Hrs' },
  { value: 'PERCENT', label: '%' },
  { value: 'REPS', label: 'Reps' },
  { value: '-', label: '-' },
  { value: 'DEGREES', label: '°' },
  { value: 'METRES', label: 'm' },
  { value: 'CM', label: 'cm' },
  { value: 'MM', label: 'mm' },
  { value: 'SETS', label: 'sets' },
  { value: 'MAX_ACCELERATION_SPEED', label: '% MAS' },
  { value: 'MAX_HR', label: '% Max HR' },
  { value: 'ONE_REP_MAX', label: '% 1 RPM' },
  { value: '%1RM', label: '% 1 RM' },
  { value: 'METRES_PER_SEC', label: 'm/s' },
  { value: 'KMH', label: 'km/h' },
  { value: 'RPE', label: 'RPE' },
  { value: 'RIR', label: 'RIR' },
  { value: 'WATTS', label: 'Watts (W)' },
];

export const MEASUREMENTS = [
  { value: 'G', label: 'G' },
  { value: 'LB', label: 'LB' },
  { value: 'KG', label: 'KG' },
  { value: 'ML', label: 'ML' },
  { value: 'L', label: 'L' },
  { value: 'OZ', label: 'OZ' },
  { value: 'QUART', label: 'Quart' },
  { value: 'PINT', label: 'Pint' },
  { value: 'TEASPOON', label: 'Teaspoon' },
  { value: 'TABLESPOON', label: 'Tablespoon' },
  { value: 'CUP', label: 'Cup' },
];

// Recurrence
// -------------------------------------------------------------------------

export const RECURRENCE_TYPES = [
  { value: 'DAY', label: 'Daily' },
  { value: 'WEEK', label: 'Weekly' },
  { value: 'MONTH', label: 'Monthly' },
  { value: 'YEAR', label: 'Yearly' },
];

// Events
// -------------------------------------------------------------------------

export const EVENT_TYPES = [
  {
    label: 'Appointment',
    value: 'MEETING',
    color: COLOURS.appointment,
    subType: true,
    table: 'appointment',
    icon: <AppointmentIcon2 />,
  },
  {
    label: 'Competition',
    value: 'COMPETITION',
    color: COLOURS.game,
    subType: true,
    table: 'appointment',
    icon: <GameIcon2 />,
  },
  {
    label: 'Meal',
    value: 'MEAL',
    color: COLOURS.meal,
    subType: false,
    table: 'meal',
    icon: <MealIcon2 />,
  },
  {
    label: 'Meeting',
    value: 'MEETING2',
    color: COLOURS.MEETING2,
    subType: true,
    table: 'appointment',
    icon: <MeetingIcon />,
  },
  {
    label: 'S&C / Rehab',
    value: 'WORKOUT',
    color: COLOURS.workout,
    subType: true,
    table: 'workout',
    icon: <RehabIcon />,
  },
  {
    label: 'Training',
    value: 'TRAINING',
    color: COLOURS.training,
    subType: true,
    table: 'workout',
    icon: <TrainingIcon2 />,
  },
  {
    label: 'Travel',
    value: 'TRAVEL',
    color: COLOURS.TRAVEL,
    subType: true,
    table: 'appointment',
    icon: <TravelIcon />,
  },
];

export const getEventTypeInfo = (sessionType) =>
  EVENT_TYPES.find((evT) => evT.value === sessionType);

// Forms
// -------------------------------------------------------------------------

export const FORM_TYPES = [
  { label: 'After an event', value: 'TRIGGER' },
  // { label: "At a regular interval", value: "INTERVAL" },
  { label: 'Once, on a specific time and date', value: 'DATE' },
];

// WARNING: Keep up to date with Forms.js -> _renderWhen()
export const FORM_TRIGGERS = [
  { label: 'Workout Completed', value: 'WORKOUT_COMPLETE' },
  { label: 'Training Completed', value: 'TRAINING_COMPLETE' },
  { label: 'Game Completed', value: 'COMPETITION_COMPLETE' },
  { label: 'Appointment Completed', value: 'APPOINTMENT_COMPLETE' },
  { label: 'Meal Completed', value: 'MEAL_COMPLETE' },
];

// Onboarding
// -------------------------------------------------------------------------

export const ONBOARDING_FIELD_TYPES_KEYED = {
  TEXT: { label: 'Text', value: 'TEXT' },
  DROPDOWN: { label: 'Dropdown', value: 'DROPDOWN' },
  CONFIRM: { label: 'Confirm', value: 'CONFIRM' },
};

export const ONBOARDING_FIELD_TYPES = Object.values(
  ONBOARDING_FIELD_TYPES_KEYED
);

// Screening
// -------------------------------------------------------------------------

export const SCREENING_TYPES = [
  { label: 'Number', value: 'NUMBER' },
  { label: 'Drop-down', value: 'DROPDOWN' },
];

export const DISPLAY_INTERVALS = [
  { label: 'Daily', value: null },
  { label: 'Weekly', value: 'WEEK' },
  { label: 'Monthly', value: 'MONTH' },
  { label: 'Yearly', value: 'YEAR' },
];

// Images
// =========================================================================

export const DIAGRAMS = {
  MALE: {
    FRONT: MaleFront,
    BACK: MaleBack,
    LEFT: MaleLeft,
    RIGHT: MaleRight,
  },
  FEMALE: {
    FRONT: FemaleFront,
    BACK: FemaleBack,
    LEFT: FemaleLeft,
    RIGHT: FemaleRight,
  },
};

export const DIAGRAMS_GREY = {
  MALE: {
    FRONT: MaleFrontGrey,
    BACK: MaleBackGrey,
    LEFT: MaleLeftGrey,
    RIGHT: MaleRightGrey,
    SIDE: MaleRightGrey,
  },
  FEMALE: {
    FRONT: FemaleFrontGrey,
    BACK: FemaleBackGrey,
    LEFT: FemaleLeftGrey,
    RIGHT: FemaleRightGrey,
    SIDE: FemaleRightGrey,
  },
};

// Storage Prefixes
// =========================================================================

export const LOAD_STORAGE_PREFIX = '/loadWellness/load';
export const SCHEDULE_STORAGE_PREFIX = '/schedule';

//
// =========================================================================

export const venueOptions = ['Home', 'Away', 'Neutral'].map((v) => ({
  label: v,
  value: v.toLocaleLowerCase(),
}));
