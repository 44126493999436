import React from 'react';

export default function TravelIcon() {
  return (
    <svg width="18px" height="18px" viewBox="0 0 18 18">
      <g
        id="Schedule-2.0"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Schedule-/OverviewCopy"
          transform="translate(-518.000000, -770.000000)"
          stroke="#8A96A0"
          strokeWidth="1.5"
        >
          <g id="Group-3" transform="translate(494.000000, 590.000000)">
            <g id="Group" transform="translate(25.000000, 181.000000)">
              <circle id="Oval" cx="8" cy="8" r="8"></circle>
              <polygon
                id="Path"
                points="8.61538462 12.3076923 11.0769231 4.92307692 3.69230769 7.38461538 6.76923077 9.23076923"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
