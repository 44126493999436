import React from 'react';

export default function TrainingIcon2() {
  return (
    <svg width="18px" height="18px" viewBox="0 0 18 18">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-518.000000, -738.000000)"
          stroke="#8A96A0"
          strokeWidth="1.5"
        >
          <g id="Group-3" transform="translate(494.000000, 590.000000)">
            <g id="Group" transform="translate(25.000000, 149.000000)">
              <polyline
                id="Path"
                points="9.84615385 14.7692308 10.7815385 15.6676923 16 10.4369231 13.3907692 7.82769231 10.3261538 10.8923077"
              ></polyline>
              <polyline
                id="Path"
                points="5.09538462 5.68615385 8.17230769 2.60923077 5.55076923 0 0.332307692 5.21846154 1.23076923 6.15384615"
              ></polyline>
              <line
                x1="11.6430769"
                y1="9.57538462"
                x2="6.42461538"
                y2="4.35692308"
                id="Path"
              ></line>
              <circle
                id="Oval"
                cx="3.69230769"
                cy="12.3076923"
                r="3.69230769"
              ></circle>
              <line
                x1="4.61538462"
                y1="11.3846154"
                x2="3.69230769"
                y2="12.3076923"
                id="Path"
              ></line>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
