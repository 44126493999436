import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import gql from 'graphql-tag';

class Permissions extends Component {
  // Properties
  // =========================================================================

  props: {
    can: string,
  };

  state = {
    canAccess: false,
  };

  static permissions = {};

  // React
  // =========================================================================

  static getDerivedStateFromProps(props, state) {
    Permissions.permissions = props.permissions;
    const canAccess = Permissions.can(props.can, props.permissions);

    if (canAccess !== state.canAccess) return { canAccess };

    return null;
  }

  // Render
  // =========================================================================

  render() {
    if (this.state.canAccess === false) return null;

    return this.props.children;
  }

  // Helpers
  // =========================================================================

  static can(can, permissions = Permissions.permissions) {
    if (!can) return true;

    if (!permissions) return false;

    if (permissions === true) return true;

    const [action, section] = can.split(':');

    if (!permissions.hasOwnProperty(section)) return false;

    if (permissions[section] === true) return true;

    return action.split(',').every((p) => permissions[section].indexOf(p) > -1);
  }
}

// Data
// =============================================================================

const GET_PERMISSIONS = graphql(
  gql`
    query GetPermissions {
      permissions
    }
  `,
  {
    options: {
      fetchPolicy: 'cache-and-network',
    },
    props: ({ data: { permissions } }) => ({
      permissions,
    }),
  }
);

export default compose(
  // Queries
  // =========================================================================

  // Get Permissions
  // -------------------------------------------------------------------------

  GET_PERMISSIONS
)(Permissions);

// HOC
// =============================================================================

/**
 * Passes `can('action:section')` to the wrapped components props.
 *
 * ```js
 * if (this.props.can('access:settings')) {
 *     // ...
 * }
 * ```
 *
 * @param WrappedComponent
 * @param propName
 * @return {*}
 */
export const withPermissions = (WrappedComponent, propName = 'can') =>
  compose(
    // Queries
    // =========================================================================

    // Get Permissions
    // -------------------------------------------------------------------------

    GET_PERMISSIONS
  )(
    class extends Component {
      // Render
      // =========================================================================

      render() {
        const props = { ...this.props },
          perms = this.props.permissions;

        delete props.permissions;

        props[propName] = (can) => Permissions.can(can, perms);
        props.__hasPermissions = typeof perms !== typeof void 0;

        return <WrappedComponent {...props} />;
      }
    }
  );
