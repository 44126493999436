import React from 'react';
import { COLOURS } from '../consts';

export default (
  <svg
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
  >
    <g fill={COLOURS.warning}>
      <path d="M0.349,49h49.302L25,1.842L0.349,49z M3.651,47L25,6.159L46.349,47H3.651z" />
      <rect height="18" width="2" x="24" y="18" />
      <rect height="3" width="2" x="24" y="39" />
    </g>
  </svg>
);
