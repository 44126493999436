// prettier-ignore
export default function injectIntercom(currentUser) {
  if(!currentUser || window.intercomSettings != null) return;
  
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    name: currentUser.name,
    email: currentUser.email,
    organisation: currentUser.organisation,
    role: currentUser.role
  };

  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src=`https://widget.intercom.io/widget/${process.env.REACT_APP_INTERCOM_APP_ID}`;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}
