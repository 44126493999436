// @flow

import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

type PageInfo = {
  pathname: string,
  scroll: number,
};

class ScrollMemory extends PureComponent {
  // Properties
  // =========================================================================

  props: {
    location?: Object,
  };

  url = [];
  requestId = 0;

  // React
  // =========================================================================

  componentDidMount() {
    window.addEventListener('popstate', this.onPopStateChange);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onPopStateChange);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const current = this.props.location,
      next = nextProps.location;

    if (next !== current) {
      //if (!next.state?.allowScroll) this.requestId = ScrollMemory.scrollTo(0);
      this.url = ScrollMemory.saveUrl(
        this.url,
        current.pathname,
        ScrollMemory.getScrollPage()
      );
    }
  }

  // Events
  // =========================================================================

  onPopStateChange = () => {
    const pathname = window.location.pathname;
    const nextFind = ScrollMemory.getPage(this.url, pathname);

    if (this.requestId > 0) {
      window.cancelAnimationFrame(this.requestId);
      this.requestId = 0;
    }

    if (nextFind) {
      ScrollMemory.scrollTo(nextFind.scroll);
    }
  };

  // Render
  // =========================================================================

  render = () => null;

  // Helpers
  // =========================================================================

  static getPage(url: PageInfo[], path: string): ?PageInfo {
    const lastIndex = url.map((x) => x.pathname).indexOf(path);
    return lastIndex > -1 ? url[lastIndex] : undefined;
  }

  static getIndexPage(url: PageInfo[], path: string): number {
    return url.map((x) => x.pathname).indexOf(path);
  }

  static saveUrl(
    url: PageInfo[],
    pathname: string = '',
    scroll: number = 0
  ): PageInfo[] {
    const u = Array.isArray(url) ? url : [],
      page = ScrollMemory.getIndexPage(u, pathname);

    if (page > -1) {
      u[page].scroll = scroll;
      return u;
    }

    u.push({ pathname, scroll });
    return u;
  }

  static getScrollPage(): number {
    let docScrollTop = 0;

    if (document.documentElement && document.documentElement !== null)
      docScrollTop = document.documentElement.scrollTop;

    return window.pageYOffset || docScrollTop;
  }

  static scrollTo(scrollNumber: number = 0): number {
    window.requestAnimationFrame(() => {
      window.scrollTo(0, scrollNumber);
    });
  }
}

export default withRouter(ScrollMemory);
