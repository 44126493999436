// @flow

import React, { PureComponent } from 'react';
import lazyImages from '../_helpers/lazyImages';

export default class Image extends PureComponent {
  // Variables
  // =========================================================================

  props: {
    src: string,
    alt: string,
    srcset?: string,
    className?: string,
    onLoad?: Function,
    style?: Object,
    width?: number,
    height?: number,
    placeholderWidth?: number,
    placeholderHeight?: number,
  };

  static defaultProps = {
    style: {},
    width: 0,
    height: 0,
    placeholderWidth: 0,
    placeholderHeight: 0,
  };

  img = null;

  // React
  // =========================================================================

  componentWillUnmount() {
    if (this.img) lazyImages.unobserve(this.img);
  }

  // Render
  // =========================================================================

  render() {
    const props = { ...this.props };
    delete props.style;
    delete props.src;
    delete props.srcset;
    delete props.placeholderWidth;
    delete props.placeholderHeight;

    if (props.hasOwnProperty('width') && props.width === 0) delete props.width;

    if (props.hasOwnProperty('height') && props.height === 0)
      delete props.height;

    const style = {
      ...this.props.style,
      opacity: 0,
      transition: 'opacity 0.15s ease',
    };

    if (this.props.src) props['data-src'] = this.props.src;

    if (this.props.srcset) props['data-srcset'] = this.props.srcset;

    // eslint-disable-next-line jsx-a11y/alt-text
    return (
      <img
        {...props}
        alt={props.alt}
        src={`data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%27${
          this.props.placeholderWidth || this.props.width
        }%27%20height%3D%27${
          this.props.placeholderHeight || this.props.height
        }%27%20style%3D%27background%3Atransparent%27%2F%3E`}
        style={style}
        ref={(el) => {
          if (el && !this.img) {
            lazyImages.observe(el);
            this.img = el;
          }
        }}
      />
    );
  }
}
