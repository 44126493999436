// @flow

import React, { Component } from 'react';
import Spinner from '../_components/Spinner/Spinner';

const asyncComponent = (importComponent) => {
  return class extends Component {
    state = { component: null };

    componentDidMount() {
      importComponent()
        .then(({ default: component }) => {
          this.setState({ component });
        })
        .catch((e) => {
          // if (e.message.toLowerCase().indexOf('chunk') > -1)
          // 	window.location.reload(true);
          // else
          console.error(e);
        });
    }

    render() {
      const C = this.state.component;
      return C ? <C {...this.props} /> : <Spinner show block centre />;
    }
  };
};

export default asyncComponent;
