import React from 'react';

export default function MeetingIcon() {
  return (
    <svg width="18px" height="16px" viewBox="0 0 18 16">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-518.000000, -707.000000)"
          stroke="#8A96A0"
          strokeWidth="1.5"
        >
          <g id="Group-3" transform="translate(494.000000, 590.000000)">
            <g id="Group" transform="translate(25.000000, 118.000000)">
              <ellipse
                id="Oval"
                cx="2.00064"
                cy="2.10052412"
                rx="2.00064"
                ry="2.10052412"
              ></ellipse>
              <path
                d="M1.9999872,4.20071227 L1.5131648,8.28970568 C1.417664,9.09181562 2.0133632,9.8003091 2.7832832,9.8003091 L5.333312,9.8003091 L5.333312,14"
                id="Path"
              ></path>
              <path
                d="M12,2.1008601 C12,3.26056982 12.895488,4.20069883 14,4.20069883 C15.104512,4.20069883 16,3.26056982 16,2.1008601 C16,0.941136944 15.104512,0.00100792904 14,0.00100792904 C12.895488,0.00100792904 12,0.941136944 12,2.1008601 Z"
                id="Path"
              ></path>
              <path
                d="M14,4.20071227 L14.486784,8.28970568 C14.582272,9.09182906 13.98656,9.8003091 13.21664,9.8003091 L10.6666496,9.8003091 L10.6666496,14"
                id="Path"
              ></path>
              <line
                x1="3.9470336"
                y1="6.97431797"
                x2="11.947008"
                y2="6.97431797"
                id="Path"
              ></line>
              <line
                x1="7.946944"
                y1="6.97431797"
                x2="7.946944"
                y2="14"
                id="Path"
              ></line>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
