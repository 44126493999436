import React, { Component } from 'react';
import AuthLayout from './_layout';
import Helmet from '../_components/Helmet';

class Suspended extends Component {
  // Render
  // =========================================================================

  render() {
    return (
      <AuthLayout>
        <Helmet title="Account Suspended" />

        <h3>Account Suspended</h3>

        <br />

        <p>
          The account associated with domain is currently suspended. Please
          contact your account administrator to resolve this issue.
        </p>
        <p>
          If you are the account administrator, please contact your account
          manager at TrainMyAthlete to resolve.
        </p>
      </AuthLayout>
    );
  }
}

export default Suspended;
