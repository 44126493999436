// @flow

import React, { PureComponent } from 'react';
import { default as ReactHelmet } from 'react-helmet';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';

class Helmet extends PureComponent {
  // Variables
  // =========================================================================

  props: {
    title?: string,
  };

  // Render
  // =========================================================================

  render() {
    const { application } = this.props;
    const title = application ? application.name : 'TrainMyAthlete';

    return (
      <ReactHelmet
        defaultTitle={title}
        titleTemplate={`%s - ${title}`}
        {...this.props}
      />
    );
  }
}

export default graphql(
  gql`
    query GetApplicationName {
      currentApplication {
        name
      }
    }
  `,
  {
    props: ({ data: { currentApplication } }) => ({
      application: currentApplication ? currentApplication : null,
    }),
  }
)(Helmet);
