import React from 'react';

export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#F8C81C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      transform="translate(2 2)"
    >
      <path d="M32,16 C32,24.8349091 24.8349091,32 16,32 C7.16218182,32 0,24.8349091 0,16 C0,7.16509091 7.16218182,0 16,0 C24.8349091,0 32,7.16509091 32,16 Z" />
      <polyline points="8.821 16 14.545 21.818 23.965 11.706" />
    </g>
  </svg>
);
