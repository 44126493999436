import React from 'react';

export default (
  <svg width="30px" height="30px" viewBox="0 0 30 30">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <circle fill="#41494F" cx="15" cy="15" r="15" />
      <path
        d="M9.15893555,19.5532227 L20.8410645,19.5532227 C21.595459,19.5532227 22.0568848,19.1430664 22.0568848,18.527832 C22.0568848,17.7514648 21.3537598,17.0849609 20.6945801,16.4550781 C20.1745605,15.949707 20.057373,14.8950195 19.9621582,13.9208984 C19.8449707,11.2402344 19.0612793,9.35058594 17.1643066,8.66943359 C16.8640137,7.70263672 16.0803223,6.97021484 15.0036621,6.97021484 C13.9196777,6.97021484 13.1359863,7.70263672 12.8356934,8.66943359 C10.9460449,9.35058594 10.1477051,11.2402344 10.0378418,13.9208984 C9.94995117,14.8950195 9.82543945,15.949707 9.30541992,16.4550781 L9.02470803,16.7274835 C8.46507793,17.2776327 7.94311523,17.8623744 7.94311523,18.527832 C7.94311523,19.1430664 8.41186523,19.5532227 9.15893555,19.5532227 Z M12.6745605,20.5786133 L17.3327637,20.5786133 C17.244873,21.6772461 16.3000488,22.5927734 15.0036621,22.5927734 C13.7072754,22.5927734 12.7624512,21.6772461 12.6745605,20.5786133 Z"
        fill="#8A96A0"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
