import React from 'react';
import styles from './ErrorList.less';

export default ({ errors, style }) =>
  errors.length > 0 && (
    <ul className={styles.errorList} style={style}>
      {errors.map((err, i) => (
        <li key={i}>
          {typeof err === 'string'
            ? err
            : Array.isArray(err)
            ? err[0]
            : err.hint || err.message || err}
        </li>
      ))}
    </ul>
  );
