class Lazy {
  // Properties
  // =========================================================================

  observer = null;

  constructor() {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio <= 0) return;

        const target = entry.target;
        target.addEventListener('load', this.onLoad.bind(this, target));

        if (target.dataset.src) {
          target.src = target.dataset.src;
          target.removeAttribute('data-src');
        }

        if (target.dataset.srcset) {
          target.srcset = target.dataset.srcset;
          target.removeAttribute('data-srcset');
        }

        this.observer.unobserve(target);
      });
    });
  }

  // Actions
  // =========================================================================

  observe(target) {
    this.observer.observe(target);
  }

  unobserve(target) {
    this.observer.unobserve(target);
  }

  // Events
  // =========================================================================

  onLoad(target) {
    target.removeEventListener('load', this.onLoad.bind(this, target), true);
    target.style.opacity = 1;
  }
}

const lazyImages = new Lazy();
export default lazyImages;
