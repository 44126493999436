import React from 'react';

export default function GameIcon2() {
  return (
    <svg width="20px" height="16px" viewBox="0 0 20 16">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-517.000000, -643.000000)"
          stroke="#8A96A0"
          strokeWidth="1.5"
        >
          <g id="Group-3" transform="translate(494.000000, 590.000000)">
            <g id="Group" transform="translate(24.000000, 54.000000)">
              <path
                d="M2,0 L16,0 C17.1045695,-2.02906125e-16 18,0.8954305 18,2 L18,12 C18,13.1045695 17.1045695,14 16,14 L2,14 C0.8954305,14 1.3527075e-16,13.1045695 0,12 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z"
                id="Path"
              ></path>
              <line x1="9" y1="0" x2="9" y2="14" id="Path"></line>
              <path
                d="M6.6,7 C6.6,8.40581573 7.6745166,9.54545455 9,9.54545455 C10.3254834,9.54545455 11.4,8.40581573 11.4,7 C11.4,5.59418427 10.3254834,4.45454545 9,4.45454545 C7.6745166,4.45454545 6.6,5.59418427 6.6,7 Z"
                id="Path"
              ></path>
              <path
                d="M0,3.81818182 L2.4,3.81818182 C3.0627417,3.81818182 3.6,4.38800123 3.6,5.09090909 L3.6,8.90909091 C3.6,9.61199877 3.0627417,10.1818182 2.4,10.1818182 L0,10.1818182"
                id="Path"
              ></path>
              <path
                d="M18,3.81818182 L15.6,3.81818182 C14.9372583,3.81818182 14.4,4.38800123 14.4,5.09090909 L14.4,8.90909091 C14.4,9.61199877 14.9372583,10.1818182 15.6,10.1818182 L18,10.1818182"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
