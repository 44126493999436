import React from 'react';

export default function AppointmentIcon2() {
  return (
    <svg width="18px" height="18px" viewBox="0 0 18 18">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Schedule-/-Overview-Copy"
          transform="translate(-518.000000, -610.000000)"
          stroke="#8A96A0"
          strokeWidth="1.5"
        >
          <g id="Group-3" transform="translate(494.000000, 590.000000)">
            <g id="Group" transform="translate(25.000000, 21.000000)">
              <path
                d="M14.2222222,7.82222222 L14.2222222,13.8666667 C14.2222222,15.0448741 13.2670964,16 12.0888889,16 L2.13333333,16 C0.955125867,16 0,15.0448741 0,13.8666667 L0,3.91111111 C0,2.73290364 0.955125867,1.77777778 2.13333333,1.77777778 L8.17777778,1.77777778"
                id="Path"
              ></path>
              <circle
                id="Oval"
                cx="13.1555556"
                cy="2.84444444"
                r="2.84444444"
              ></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
