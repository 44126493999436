import React from 'react';

export default function MealIcon2() {
  return (
    <svg width="18px" height="18px" viewBox="0 0 18 18">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-518.000000, -674.000000)"
          stroke="#8A96A0"
          strokeWidth="1.5"
        >
          <g id="Group-3" transform="translate(494.000000, 590.000000)">
            <g id="Group" transform="translate(25.000000, 85.000000)">
              <g>
                <rect
                  id="Rectangle"
                  x="1.23076923"
                  y="0"
                  width="14.7692308"
                  height="16"
                  rx="1.0673077"
                ></rect>
                <line
                  x1="0"
                  y1="3.69230769"
                  x2="2.46153846"
                  y2="3.69230769"
                  id="Path"
                ></line>
                <line x1="0" y1="8" x2="2.46153846" y2="8" id="Path"></line>
                <line
                  x1="0"
                  y1="12.3076923"
                  x2="2.46153846"
                  y2="12.3076923"
                  id="Path"
                ></line>
              </g>
              <path
                d="M11.0769231,8.61538462 C12.0965257,8.61538462 12.9230769,7.78883338 12.9230769,6.76923077 C12.9230769,5.74962815 12.0965257,4.92307692 11.0769231,4.92307692 L10.9784615,4.92307692 C10.7122821,4.18702622 10.013471,3.69663666 9.23076923,3.69663666 C8.44806743,3.69663666 7.74925633,4.18702622 7.48307692,4.92307692 L7.38461538,4.92307692 C6.36501277,4.92307692 5.53846154,5.74962815 5.53846154,6.76923077 C5.53846154,7.78883338 6.36501277,8.61538462 7.38461538,8.61538462 L7.38461538,9.84615385 L11.0769231,9.84615385 L11.0769231,8.61538462 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
