import styles from './Auth.less';
import TMA_LOGO from '../_imgs/Logo';
import Image from '../_components/Image';
import banner from '../_imgs/quino.jpg';
import React, { Fragment } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import gql from 'graphql-tag';
import getApplication from '../_helpers/getApplication';

const AuthLayout = ({
  onSubmit,
  children,
  validSlug,
  skipValid,
  hideFooter = false,
}) => (
  <div
    className={[styles.wrap, validSlug || skipValid ? styles.validSlug : null]
      .filter(Boolean)
      .join(' ')}
  >
    <div className={styles.form}>
      {(validSlug || skipValid) && (
        <Fragment>
          <form onSubmit={onSubmit}>
            <div className={styles['form-wrap']}>
              {TMA_LOGO}
              {children}
            </div>
          </form>

          {!hideFooter && (
            <footer>
              &copy; {new Date().getFullYear()} TrainMyAthlete Ltd
              <span>|</span>
              <a href="https://trainmyathlete.com/privacy-policy">
                Privacy Policy
              </a>
            </footer>
          )}
        </Fragment>
      )}
    </div>

    <div className={styles.img}>
      <Image src={banner} alt="TrainMyAthlete" />
    </div>
  </div>
);

export default compose(
  // Queries
  // =========================================================================

  // Is Valid Sub-domain
  // -------------------------------------------------------------------------

  graphql(
    gql`
      query IsValidSubdomain($slug: String!) {
        validSlug(slug: $slug)
      }
    `,
    {
      skip: ({ skipValid }) => skipValid,
      options: {
        variables: {
          slug: getApplication(),
        },
      },
      props: ({ data: { validSlug } }) => {
        if (['admin', 'register'].indexOf(getApplication()) > -1)
          validSlug = true;
        return { validSlug };
      },
    }
  )
)(AuthLayout);
