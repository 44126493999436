export default class Ago {
  // Properties
  // =========================================================================

  static locales = {
    prefix: '',
    suffix: '',
    separator: ' ',

    seconds: 'less than a minute ago',
    minute: 'about a minute ago',
    minutes: '%d minutes ago',
    hour: 'about an hour ago',
    hours: 'about %d hours ago',
    day: 'yesterday',
    days: '%d days ago',
    month: 'about a month ago',
    months: '%d months ago',
    year: 'about a year ago',
    years: '%d years ago',
  };

  // Methods
  // =========================================================================

  /**
   * Ago.inWords(myDate);
   *
   * @param {Date|number} timeAgo
   * @return {string}
   */
  static inWords(timeAgo) {
    if (timeAgo instanceof Date) timeAgo = timeAgo.getTime();

    const separator = this.locales.separator || ' ';

    let seconds = Math.floor((new Date() - parseInt(timeAgo)) / 1000),
      interval,
      words = this.locales.prefix + separator;

    const intervals = {
      year: seconds / 31536000,
      month: seconds / 2592000,
      day: seconds / 86400,
      hour: seconds / 3600,
      minute: seconds / 60,
    };

    let distance = this.locales.seconds;

    for (let key in intervals) {
      interval = Math.floor(intervals[key]);

      if (interval > 1) {
        distance = this.locales[key + 's'];
        break;
      } else if (interval === 1) {
        distance = this.locales[key];
        break;
      }
    }

    distance = distance.replace(/%d/i, interval);
    words += distance + separator + this.locales.suffix;

    return words.trim();
  }
}
