// @flow

import React, { PureComponent } from 'react';
import styles from './Button.less';
import { Link } from 'react-router-dom';

export default class Button extends PureComponent {
  // Variables
  // =========================================================================

  props: {
    type?: 'submit' | 'button' | 'reset' | 'link',
    size?: 'small' | 'large' | 'huge',
    look?:
      | 'primary'
      | 'secondary'
      | 'tertiary'
      | 'quaternary'
      | 'dashed'
      | 'danger'
      | 'none'
      | 'text'
      | 'outline'
      | 'brand'
      | 'info'
      | 'success'
      | 'danger-small',
    wide?: boolean,
    busy?: boolean,

    disabled?: boolean,
    title?: string,

    onClick?: Function,

    className?: string,
    children: string,
    to?: string | object,
    target?: string,
    style?: Object,
  };

  static defaultProps = {
    type: 'submit',
    size: 'large',
    look: 'primary',
  };

  // Render
  // =========================================================================

  render() {
    const {
      children,
      type,
      size,
      look,
      wide,
      disabled,
      onClick,
      className,
      to,
      busy,
      title,
      style,
      target,
    } = this.props;

    const cls = [styles.button];

    cls.push(styles[look]);
    cls.push(styles[size]);

    if (wide) cls.push(styles.wide);

    if (className) cls.push(className);

    if (busy) cls.push(styles.busy);

    if (type === 'link') {
      const path = to.pathname ?? to;
      if (path.indexOf('//') > -1 || path.indexOf('mailto:') > -1) {
        return (
          <a
            href={path}
            className={cls.join(' ')}
            title={title}
            style={style}
            target={target}
            rel={target === '_blank' ? 'noopener noreferrer' : ''}
          >
            <span>{children}</span>
          </a>
        );
      }

      return (
        <Link
          to={to}
          disabled={disabled}
          className={cls.join(' ')}
          title={title}
          style={style}
        >
          <span>{children}</span>
        </Link>
      );
    }

    return (
      <button
        type={type}
        onClick={onClick}
        className={cls.join(' ')}
        disabled={disabled}
        title={title}
        style={style}
      >
        <span>{children}</span>
      </button>
    );
  }
}
