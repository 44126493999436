import React, { useState, useEffect } from 'react';
import styles from './NewsFeedItem.less';
import FormatDate from '../../_helpers/formatDate';
import gql from 'graphql-tag';
import Avatar from '../Avatar';
import { Link } from 'react-router-dom';

// Data
// =============================================================================

export const FEED_ITEM_QUERY = gql`
  fragment FeedItemQuery on Activity {
    id
    nodeId
    type
    createdAt
    action
    meta
    read
    personByActionedById {
      id
      fullName
      assetByAvatar {
        urls
      }
    }
    personByToId {
      id
      fullName
    }
  }
`;

// Helpers
// =============================================================================

function getType(type, person, meta) {
  type = type.toLowerCase();

  let title = type;

  if (meta && meta.name) title += ` '${meta.name}'`;

  if (person === null) return title;

  let url = '#type';

  // eslint-disable-next-line default-case
  switch (type) {
    case 'workout':
      url = `/athletes/${person.id}/workout/${meta.id}`;
      break;
  }

  return <Link to={url}>{title}</Link>;
}

function getWho(person) {
  return <Link to={`/athletes/${person.id}`}>{person.fullName}</Link>;
}

// Render
// =============================================================================

export default ({
  item: {
    type,
    createdAt,
    action,
    personByActionedById,
    personByToId,
    meta,
    read,
  },
}) => {
  const [cls, setCls] = useState(
    [styles.item, read ? null : styles.unread].filter(Boolean)
  );

  useEffect(() => {
    if (cls.length === 1) return;

    requestAnimationFrame(() => {
      const nextCls = [...cls];
      nextCls.pop();
      setCls(nextCls);
    });
  });

  return (
    <div className={cls.join(' ')}>
      <Avatar person={personByActionedById} />

      <div>
        <p>
          <strong>
            <em>{personByActionedById.fullName}</em>
          </strong>
          {' ' + action.toLowerCase() + 'd a'}{' '}
          <em>{getType(type, personByToId, meta)}</em>
          {personByToId && ' for '}
          {personByToId && <em>{getWho(personByToId)}</em>}
        </p>

        <time dateTime={createdAt}>{FormatDate.dateTime(createdAt, true)}</time>
      </div>
    </div>
  );
};
