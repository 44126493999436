import regionsByFirstDayOfWeek from './regionsByFirstDayOfWeek';

const locale = new Intl.Locale(navigator.language);

export default function getFirstDayOfWeek() {
  if (locale.weekInfo) return locale.weekInfo.firstDay % 7;

  return (
    regionsByFirstDayOfWeek.find((d) => d.regions.includes(locale.region))
      ?.id ?? 1
  );
}
