import React from 'react';

export default function RehabIcon() {
  return (
    <svg width="18px" height="16px" viewBox="0 0 18 16">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-518.000000, -803.000000)"
          stroke="#8A96A0"
          strokeWidth="1.5"
        >
          <g id="Group-3" transform="translate(494.000000, 590.000000)">
            <g id="GroupCopy-7" transform="translate(25.000000, 214.000000)">
              <line
                x1="6.15384615"
                y1="8.9095794"
                x2="9.84615385"
                y2="8.9095794"
                id="Path"
              ></line>
              <line
                x1="8"
                y1="7.00067168"
                x2="8"
                y2="10.8184871"
                id="Path"
              ></line>
              <path
                d="M16,5.09176395 C10.7922289,3.39946958 5.20777111,3.39946958 0,5.09176395 L2.46153846,14 L13.5384615,14 L16,5.09176395 Z"
                id="Path"
              ></path>
              <path
                d="M13.4030769,4.45546138 L11.9630769,0.892166957 C11.7960031,0.340735387 11.2905847,-0.0250834953 10.7323077,0.00134335182 L5.21846154,0.00134335182 C4.6601845,-0.0250834953 4.15476611,0.340735387 3.98769231,0.892166957 L2.59692308,4.45546138"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
